<mat-card
  appearance="outlined"
  class="lib-fill"
  [ngStyle]="options?.style || null"
  >
  <mat-card-header class="'lib-flex-grow lib-horizontal-center">
    <mat-card-title>{{ title }}</mat-card-title>
    @if (subTitle) {
      <mat-card-subtitle>{{ subTitle }}</mat-card-subtitle>
    }
  </mat-card-header>
  <mat-card-content class="'lib-flex-grow">
    <ng-content></ng-content>
  </mat-card-content>
  @if (actions && !options?.noAction) {
    <mat-card-actions
      class="'lib-flex-grow"
      >
      <ng-container [ngTemplateOutlet]="actions"></ng-container>
    </mat-card-actions>
  }
</mat-card>
