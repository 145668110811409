import { NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LibCardShellOptions } from './models/lib-card-shell.model';

@Component({
  selector: 'lib-card-shell',
  standalone: true,
  imports: [NgStyle, NgTemplateOutlet, MatCardModule, MatButtonModule],
  templateUrl: './lib-card-shell.component.html',
  styleUrls: ['./lib-card-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibCardShellComponent {
  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() actions?: TemplateRef<any> | null;
  @Input() options?: LibCardShellOptions;
}
